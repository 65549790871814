<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(addUpdateItemAction)" @reset.prevent="resetForm" v-if="subscriptionData != null">
        <b-row style="margin-bottom: 20px">
          <b-col cols="9">
            <h6 class="section-label text-primary float-left" style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              ">
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }}
            </h6>
          </b-col>

          <b-col cols="3">
            <b-button v-if="$router.name == 'subscription-view'" variant="outline-primary" class="btn-sm float-right" @click="
              $router.push({
                name: 'subscription-view',
                params: { id: subscriptionData.id + 1 },
              })
              ">
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button v-if="$router.name == 'subscription-view'" variant="outline-primary" class="btn-sm float-right" @click="
              $router.push({
                name: 'subscription-view',
                params: { id: subscriptionData.id - 1 },
              })
              ">
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button variant="outline-primary" class="btn-sm float-right" @click="
              $router.push({
                name: 'subscription-list',
              })
              ">
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Souscriptions</span>
            </b-button>
          </b-col>
        </b-row> 

        <b-row>
          <!-- package_code -->
          <b-col cols="12" md="6">
            <validation-provider #default="validationContext" name="package_code" rules="required">
              <b-form-group>

                <label for="package_code" style="width: 100%; max-height: 13px;">
                  <span class="align-left pull-left" style="float: left;">
                    Package à souscrire
                  </span>  
                </label>

                <v-select id="package_code" v-model="subscriptionData.package_code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="packagesListOptions"
                  :reduce="(val) => val.value" trim :clearable="false" />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Choisissez le Package à Souscrire svp
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <hr />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button ref="refSubmitEl" type="submit" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button variant="outline-secondary" type="reset" @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="subscriptionData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BLink,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "subscription";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,

    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BLink,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      packagesListOptions: [],
    };
  },
  props: {
    subscriptionData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    // console
    if (router.currentRoute.name == "subscription-add-new") {
      this.operationTitle = "Souscrire ";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "subscription-view") {
      this.operationTitle = "Détails de la souscriptoin ";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "subscription-edit") {
      this.operationTitle = "Modification de la souscription ";
      this.operationIcon = "EditIcon";
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }
    // load requirement
    await myApi
      .get(`package`)
      .then((response) => {
        // default action
        var data = response.data.data;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          var name = data[i].name;

          if (name.length > 15) {
            name = name.substring(0, 15);
          }

          this.packagesListOptions.push({
            label: "Nom: " + name + " | Montant: " + data[i].amount + "$",
            value: data[i].code
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      //
      this.$props.subscriptionData.name = "";
      // remove all occurences
      // initialise 1 element
      // this.$props.subscriptionData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.subscriptionData.name == "") {
        this.errors.push("Compléter le nom du subscription svp.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    async addUpdateItemAction() {
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "subscription-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "subscription-view") {
        method = "get";
        params = "/" + this.subscriptionData.id;
      }
      if (router.currentRoute.name == "subscription-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.subscriptionData.id;
      }

      // finalise form data
      formData = this.$props.subscriptionData;

      await myApi({
        url: "subscription" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "subscription-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "BellIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          /////////////////////////////////////
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "subscription-view" });
    },
    goToNextItem() {
      router.push({ name: "subscription-list" });
    },
    goToList() {
      router.push({ name: "subscription-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-subscription";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetsubscriptionData = () => {
      this.$props.subscriptionData = this.$props.subscriptionData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetsubscriptionData);

    let typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const subscriptionOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const subscriptionMethods = ["Bank Account", "PayPal", "UPI Transfer"];

    return {
      // itemsList,
      // itemsOptions,
      subscriptionMethods,
      refFormObserver,
      getValidationState,
      resetForm,
      // permissionsData,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.subscription-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
